<template>
  <div>
    <!-- navigation  -->
    <div
      class="fixed top-0 left-0 z-10 w-full py-4 transition duration-200 ease-linear bg-transparent"
      :class="fixtop ? 'bg-white shadow-md' : ' bg-gradient-to-b from-white'"
    >
      <div class="container">
        <div class="flex items-center justify-between w-full">
          <div class="w-2/3 md:w-1/2 lg:w-1/3">
            <img src="/images/main-logo.png" alt="" class="w-32" />
          </div>
          <div class="w-1/12 lg:hidden">
            <!-- <button @click="showMenu()" class="inline-block sidebar-toggle-btn">
              <svg
                class="w-8 h-8 fill-current"
                :class="fixtop ?'text-green-1':'text-black'"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button> -->
          </div>
          <!-- <div class="hidden lg:block">
            <ul
              class="inline-flex items-center font-medium"
              :class="fixtop ? 'text-gray-800' : 'text-black'"
            >
              <li class="mx-2 transition duration-300 hover:text-green-600">
                <a
                  href="#"
                  v-scroll-to="{ el: '#our_services', duration: 1500 }"
                  >Our Services</a
                >
              </li>
              <li class="mx-2 transition duration-300 hover:text-green-600">
                <a
                  href="#"
                  v-scroll-to="{ el: '#why_choose_us', duration: 1500 }"
                  >Why Choose Us</a
                >
              </li>
              <li class="mx-2 transition duration-300 hover:text-green-600">
                <a href="#" v-scroll-to="{ el: '#about_us', duration: 1500 }"
                  >About Us</a
                >
              </li>
              <li class="ml-2 transition duration-300 hover:text-green-600">
                <p
                  class="py-1 text-center text-white bg-red-500 rounded-md w-44"
                >
                  <a href="https://wa.me/60192911166">Get A Free Quote</a>
                </p>
              </li>
            </ul>
          </div> -->
        </div>
      </div>

      <!-- SIDEBAR -->
      <!-- <div
        class="relative z-50 transition duration-300 ease-in-out lg:hidden"
        :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'"
      >
        <ul class="pt-6 text-base text-center text-gray-600 bg-white shadow-md">
          <li
            @click="closeNav"
            class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white text-shadow"
          >
            <a href="#" v-scroll-to="{ el: '#our_services', duration: 1500 }"
              >Our Services</a
            >
          </li>
          <li
            @click="closeNav"
            class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white text-shadow"
          >
            <a href="#" v-scroll-to="{ el: '#why_choose_us', duration: 1500 }"
              >Why Choose Us</a
            >
          </li>
          <li
            @click="closeNav"
            class="py-3 hover:bg-black hover:bg-opacity-60 hover:text-white text-shadow"
          >
            <a href="#" v-scroll-to="{ el: '#about_us', duration: 1500 }"
              >About Us</a
            >
          </li>
          <li @click="closeNav" class="py-3">
            <p
              class="w-48 py-1 mx-auto text-center text-white bg-red-500 rounded-md"
            >
              <a href="https://wa.me/60192911166"> Get A Free Quote </a>
            </p>
          </li>
        </ul>
      </div> -->
      <!-- END SIDEBAR -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fixtop: false,
      showMobileMenu: false,
      // menu: [
      //   { a: "Our Services", link: "#our_services" },
      //   { a: "Why Choose Us", link: "" },
      //   { a: "About Us", link: "" },
      // ],
    };
  },
  methods: {
    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    scrollListener: function (e) {
      this.fixtop = window.scrollY > 150;
    },

    closeNav() {
      this.showMobileMenu = !this.showMobileMenu;
      document.body.classList.remove(true);
    },
  },
  mounted: function () {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy: function () {
    window.removeEventListener("scroll", this.scrollListener);
  },
};
</script>

<style>
.nav-menu {
  padding-top: 10px;
  position: absolute;
  width: 100%;
}
.open-menu {
  opacity: 1;
  height: 150px;
}
.closed-menu {
  opacity: 0;
  height: 0;
  padding: 0;
}
</style>
