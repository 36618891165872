<template>
  <div>
    <div class="container relative">
      <!-- banner  -->
      <div class="py-52">
        <div class="container">
          <div class="md:w-2/3 lg:w-1/2">
            <div class="px-4 py-8 text-white bg-black bg-opacity-50">
              <h1 class="py-2 text-4xl leading-none md:text-5xl">
                We Are A
                <span class="text-yellow-300">Carpet Manufacturer </span> in
                Malaysia
              </h1>
              <p
                class="font-medium leading-tight md:text-lg lg:text-base md:pt-4"
              >
              We Provide Free Professional Consultation & Installation.

              </p>

              <div
                v-if="!hideEnquiry"
                class="mt-3 space-y-1 text-gray-900 md:flex md:space-y-0 md:space-x-1"
              >
                <p
                  class="w-48 py-1 text-center transition duration-300 transform bg-yellow-300 rounded-md hover:scale-110"
                >
                  <a href="https://wa.me/60192911166"
                    ><i class="pr-1 text-lg fa-brands fa-whatsapp"></i>WhatsApp Us</a
                  >
                </p>
                <p
                  class="w-48 py-1 text-center transition duration-300 transform bg-yellow-300 rounded-md hover:scale-110"
                >
                  <a href="tel:+60192911166">Call Us 019-291 1166</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-2 right-2">
        <img src="/images/promo.png" alt="" class="w-44 md:w-60 lg:w-72" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
