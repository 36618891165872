<template>
  <div>
    <WaBtn />
    <div class="bg-center bg-no-repeat bg-cover" style="background-image: url('/images/bg-banner.jpg')">
      <TopHeader />
      <!-- main banner -->
      <MainBanner />
    </div>

    <!-- product section -->
    <div id="our_services" class="py-10">
      <div class="container">
        <h1 class="text-3xl text-center text-gray-900">
          Our <span class="text-green-1">Products</span>
        </h1>
        <p class="pt-4 text-sm leading-tight text-center text-gray-600">
          We believe that carpet making is a form of art, an intricate weaving
          of quality, excellence and creation of boundless expression.
        </p>

        <div class="pt-10 md:flex md:flex-wrap">
          <div v-for="(item, i) in product" :key="i" class="pb-10 md:w-1/2 lg:w-1/3 md:px-1">
            <div class="">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
              <div class="w-10/12 mx-auto mt-3 ">
                <p
                  class="py-2 text-lg font-semibold text-center text-white transition duration-300 ease-linear bg-green-1 hover:bg-gray-900">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div id="why_choose_us" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <h1 class="text-2xl text-center text-white lg:text-3xl">
          Why <span class="text-yellow-300">PMCY</span> Is Always Your First
          Choice
        </h1>

        <div class="pt-8 xl:max-w-5xl xl:mx-auto">
          <div class="md:w-2/3 lg:w-full lg:flex lg:flex-wrap md:mx-auto">
            <div v-for="(item, i) in choose_us" :key="i" class="pb-5 lg:w-1/2">
              <div class="md:flex md:items-start">
                <div
                  class="w-24 h-24 p-2 mx-auto transition duration-300 ease-linear border-2 border-green-500 rounded-full hover:bg-green-500 md:mx-0">
                  <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-16 pt-1 mx-auto" />
                </div>
                <div class="pt-3 text-center text-white md:w-4/5 lg:w-5/6 md:text-left md:pl-4">
                  <h1 class="text-xl leading-tight">{{ item.alt }}</h1>
                  <p class="pt-2 text-base">{{ item.p }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="lg:flex lg:items-start xl:items-center">
          <div class="mb-4 lg:mb-0 lg:w-2/3 lg:pr-2">
            <p class="pb-2 text-2xl text-gray-600 lg:text-2xl">PMCY CARPETS</p>
            <h1 class="text-4xl text-gray-900 lg:text-4xl">
              The <span class="text-green-1"> #1 Carpet Manufacturer</span> in
              Malaysia
            </h1>
            <!-- sub-icon -->
            <div class="pt-6 md:pt-10 md:flex md:flex-wrap">
              <div v-for="(item, i) in sub_info" :key="i" class="pb-5 md:px-2 md:w-1/3 lg:px-2 lg:pb-0 lg:w-1/3">
                <div class="mx-auto md:w-full">
                  <div class="w-24 h-24 p-4 mx-auto border-2 rounded-full border-green-1">
                    <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto lg:w-16" />
                  </div>
                  <div class="mt-6 text-center">
                    <p
                      class="text-base font-semibold lg:leading-tight lg:text-sm text-green-1 md:leading-tight md:h-8 lg:h-8">
                      {{ item.alt }}
                    </p>
                    <p class="pt-4 text-base leading-tight text-gray-600 md:text-xs lg:text-xs md:h-auto">
                      {{ item.p }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6">
              <p class="w-64 py-1 mx-auto text-center text-white bg-red-500 rounded-md">
                <a href="https://wa.me/60192911166">WHATSAPP US NOW!</a>
              </p>
            </div>
          </div>
          <div class="mt-10 shadow-md lg:mt-0 md:w-2/3 lg:w-1/3 md:mx-auto">
            <img src="/images/banner-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>
    <!-- about us -->
    <div id="about_us" class="py-10">
      <div class="container">
        <div class="lg:flex lg:flex-wrap lg:items-center">
          <div class="lg:w-1/2 lg:pr-4">
            <p class="text-lg font-medium text-gray-900 lg:text-2xl">
              About Us
            </p>
            <h1 class="text-4xl font-semibold text-green-1">PMCY CARPETS</h1>
            <div class="bg-blue-1 w-12 mt-3 h-0.5"></div>
            <p class="pt-6 text-base leading-tight text-gray-600">
              We believe that carpet making is a form of art, an intricate
              weaving of quality, excellence and creation of boundless
              expression. <br /><br />
              As a growing Company, PMCY faces challenges in establishing a
              solid foundation for its future growth. <br /><br />
              PMCY philosophy of
              <span class="italic font-bold text-green-1">“We stand for quality not for competition”</span>
              personifies its determination to be a successful business entity
            </p>
          </div>
          <div class="mt-4 lg:w-1/2 md:mt-10 lg:mx-auto">
            <img src="/images/about-us.jpg" alt="" class="md:w-3/4 lg:rounded-md lg:shadow-md lg:w-full md:mx-auto" />
          </div>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900 lg:text-3xl">
          Our <span class="text-green-1">Gallery</span>
        </h1>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 lg:w-1/5 md:w-1/3">
            <img :src="'/images/' + image + '.jpg'" alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md" />
          </div>
        </div>
      </div>
    </div>

    <Testimonials />

    <!-- footer -->
    <div id="element" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-contact.jpg')">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 rounded-md bg-green-1 bg-opacity-60">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo.png" alt="" class="w-2/3 mx-auto md:w-52" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-1 text-xl font-medium">PMCY CARPETS SDN BHD</h1>
              <p class="pb-2 text-base font-medium">650016-W / 200401011513</p>
              <div class="py-4">
                <p class="text-lg font-semibold leading-tight text-center text-af-darkblue">
                  No. 65, Jalan SBC 7, Taman Sri Batu Caves, 68100 Batu Caves,
                  Selangor.
                </p>
                <p class="pt-5 text-base font-bold text-center transition duration-200 transform hover:scale-110">
                  <i class="pr-2 text-2xl font-bold fa-brands fa-waze"></i>
                  <a
                    href="https://www.waze.com/en/live-map/directions/pmcy-carpets-(m)-sdn-bhd-jalan-sbc-7-batu-caves?place=w.66650144.666435907.15818888">Waze
                    Location</a>
                </p>
                <p class="pt-2 text-base font-bold text-center">
                  <i class="pr-2 fa-solid fa-phone"></i>
                  <a id="call-btn-b1" href="tel:+60192911166" class="">019-291 1166</a>
                  |
                  <a id="call-btn-b2" href="tel:+60361811166" class="">03-6181 1166</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- google map -->
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63735.68203630895!2d101.68751800000001!3d3.229991!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc479fdbee4ebf%3A0x341d513d5a1d6dbe!2sPMCY%20Carpets%20Sdn%20Bhd%20-%20Carpet%20Factory%20Malaysia!5e0!3m2!1sen!2smy!4v1686274336329!5m2!1sen!2smy"
        width="100%" height="300" style="border: 0" allowfullscreen="" loading="lazy"
        referrerpolicy="no-referrer-when-downgrade" class="mx-auto"></iframe>
    </div>
    <div class="h-2 bg-green-1"></div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    TopHeader,
    Testimonials,
    EnquiryForm,
    WaBtn,
    MainBanner,
  },
  data() {
    return {
      product: [
        { image: "product-3", alt: "Carpet Tiles" },
        { image: "product-1", alt: "Office Carpet" },
        { image: "product-2", alt: "Mosque & Surau Carpet" },
        { image: "product-4", alt: "Hotel Carpet" },
        { image: "product-5", alt: "Exhibition Carpet" },
        { image: "product-6", alt: "Custom Made Carpet" },
      ],
      choose_us: [
        {
          image: "icon-1",
          alt: "98% Customer Satisfaction",
          p: "Our customer satisfaction is our top priority",
        },
        {
          image: "icon-2",
          alt: "Excellent Service",
          p: "We provide non-obligation consultation and free installation services",
        },
        {
          image: "icon-3",
          alt: "Quality Assurance",
          p: "We ensure and assure high quality carpets",
        },
        {
          image: "icon-4",
          alt: "Value For Money",
          p: "We provide the best service and customize according to client’s budget",
        },
      ],
      sub_info: [
        {
          image: "sub-1",
          alt: "Lowest Price Guarantee",
          p: "Carpet factory in Malaysia and we provide the most competitive rates",
        },
        {
          image: "sub-2",
          alt: "Visit Our Showroom",
          p: "Carpet manufacturer in Kawasan Industrial Batu Caves. Wide selection of readily available design as well as new designs",
        },
        {
          image: "sub-3",
          alt: "Custom Carpet Consultation",
          p: "You may use your own, provide references, or even conceptualise it from scratch with us",
        },
      ],
      gallery: [
        "carpet-1",
        "carpet-2",
        "carpet-3",
        "carpet-4",
        "carpet-5",
        "carpet-6",
        // "carpet-7",
        // "carpet-8",
        // "carpet-9",
        // "carpet-10",
        "carpet-11",
        "carpet-12",
        // "carpet-13",
        "carpet-14",
        "carpet-15",
      ],
    };
  },
};
</script>
