<template>
  <div>
    <div class="py-10">
      <div class="container">
        <h1
          class="text-2xl font-semibold text-center text-gray-700 lg:text-3xl"
        >
          What Customers Say <br class="md:hidden" />
          <span class="text-green-1">About Us</span>
        </h1>

        <div class="mt-6">
          <carousel
            :autoplay="true"
            :loop="true"
            :autoplaySpeed="2500"
            :autoplayTimeout="6000"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              767: { items: 2, nav: false, dots: true },
              1000: { items: 3, nav: false, dots: true },
            }"
          >
            <div v-for="(item, i) in testimonials" :key="i" class="px-1">
              <div class="relative p-5 rounded-md bg-gray-50">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  class="w-10 h-10 fill-current text-green-1"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M4.583 17.321C3.553 16.227 3 15 3 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179zm10 0C13.553 16.227 13 15 13 13.011c0-3.5 2.457-6.637 6.03-8.188l.893 1.378c-3.335 1.804-3.987 4.145-4.247 5.621.537-.278 1.24-.375 1.929-.311 1.804.167 3.226 1.648 3.226 3.489a3.5 3.5 0 0 1-3.5 3.5c-1.073 0-2.099-.49-2.748-1.179z"
                  />
                </svg>
                <div class="pt-10">
                  <p class="h-32 text-sm text-gray-600">
                    {{ item.p }}
                  </p>
                </div>
                <div class="pt-4 text-center">
                  <p class="text-base font-semibold text-green-900">
                    {{ item.client }}
                  </p>
                  <div class="text-yellow-400">
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                    <i class="fa-sharp fa-solid fa-star"></i>
                  </div>
                </div>
                <div class="absolute left-0 right-0 mx-auto top-2 w-28 ">
                  <img src="/images/google-review.jpg" alt="" class="" />
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="hidden">
          <img src="/images/bg-testimonial.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";

export default {
  components: {
    carousel,
  },
  data() {
    return {
      testimonials: [
        {
          p: "Am very pleased with the service provided by Mr Kumar.  He was super quick in is responses and delivery.  Highly recommend this team for your carpet needs!",
          client: "Sharmila Sinnathurai",
        },
        {
          p: "Professional service and workers. They will listen to your needs and cater accordingly to it. Clean work and complete as promised. Good Job to Mr Kumar and his team.",
          client: "Jivan Daniel",
        },
        {
          p: "Good quality carpets hard to find elsewhere. They can even customize it for you, which I love most. I bought more than 3 carpets. Highly recommended. They also have carpet cleaning service.",
          client: "Radhwa AB",
        },
        {
          p: "Good to know PMCY ! Good quality & service ",
          client: "Christine Loh",
        },
      ],
    };
  },
};
</script>
